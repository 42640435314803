/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import styles from './ChooseTemplate.module.scss';
import SelectTemplate from '../selectTemplate/SelectTemplate';
import SelectedTemplatePreview from '../selectedTemplatePreview/SelectedTemplatePreview';
import { AvailableTemplates } from '../../../pkg/protobuf/v2/brain/brain_types_pb';
import { selectWedding } from '../../../app/redux/wedding.slice.recuder';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setSelectedTemplate } from '../../../app/redux/websiteBuilder.slice.reducer';
import { Icon } from '../../../shared';
import { ScreenType, useTypeOfScreen } from '../../../hooks';
import { logger } from '../../../config/Logger';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-unused-vars
export const ChooseTemplate = (props: any): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const wedding = useSelector(selectWedding);
  const [selectedTemplate, SetSelectedTemplate] = useState<AvailableTemplates>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [finalTemplate, setFinalTemplate] = useState<AvailableTemplates>();

  const SelectTheme = () => {
    if (selectedTemplate) {
      dispatch(setSelectedTemplate(selectedTemplate));
      setTimeout(() => {
        //wait for dispatch
        navigate(`/dashboard/w/${wedding.path}/website`);
      }, 1000);
    } else {
      logger.debug({ message: 'ERROR:' + selectedTemplate, functionName: 'ChooseTemplate.SelectTheme' });
    }
  };
  const [isHovered, setIsHovered] = useState(false);
  const typeOfScreen = useTypeOfScreen();
  const [showPreviewMobileWarning, setShowPreviewMobileWarning] = useState<boolean>(true);
  if (showPreviewMobileWarning && typeOfScreen !== ScreenType.desktop && typeOfScreen !== ScreenType.laptop) {
    return (
      <div style={{ width: '100vw', height: '100vh' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
            justifyContent: 'center',
            width: '100vw',
            height: '100vh',
            padding: '16px',
            alignItems: 'center',
          }}
        >
          <p className="didacticH3" style={{ textAlign: 'center' }}>
            Website builder currently works only on bigger screen devices
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.ChooseTemplate} style={typeOfScreen === ScreenType.laptop ? { overflowX: 'scroll' } : {}}>
      <div id="top">
        <h2 className="fridayH2">Website</h2>
        <svg width="376" height="1" viewBox="0 0 376 1" fill="none" xmlns="http://www.w3.org/2000/svg">
          <line x1="0.5" y1="0.5" x2="375.5" y2="0.5" stroke="#DAD8D9" strokeLinecap="round" />
        </svg>
        <h3 className="didacticH4">Let’s begin by choosing a template.</h3>
        <p>
          Find the one for you from our list below. You can always come back and change it. The template locks when you
          send your first invitation.
        </p>
        <SelectTemplate
          setSelectedTemplate={(template: any) => {
            SetSelectedTemplate(template);
          }}
          selectedTemplate={selectedTemplate}
        />
      </div>
      <div>
        <div>
          <button
            className={`btn btnMain didacticH4 ${styles.customButton}`}
            style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}
            onClick={() => {
              SelectTheme();
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            {/* <img src="/assets/icons/icon-magic-wand.svg" width={16} alt={'select theme'} /> */}
            <Icon name="magic_wand" size="s" color={isHovered ? '#FFFFFF' : '#63435b'} />
            Select Theme
          </button>
        </div>

        <SelectedTemplatePreview previewImageUrls={selectedTemplate?.previewImageUrls ?? []} />
      </div>
    </div>
  );
};

export default ChooseTemplate;
