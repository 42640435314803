import React, { ReactElement, useEffect, useState } from 'react';

import styles from './SelectedTemplatePreview.module.scss';
import { ScreenType, useTypeOfScreen } from '../../../hooks';

type SelectedTemplatePreviewProps = {
  previewImageUrls: string[];
};

export const SelectedTemplatePreview = ({ previewImageUrls }: SelectedTemplatePreviewProps): JSX.Element => {
  const typeOfScreen = useTypeOfScreen();
  const [previewView, setPreviewView] = useState<0 | 1 | 2>(0);

  const getClassNameByPreviewView = () => {
    if (typeOfScreen === ScreenType.laptop) return styles.mobilePreview;
    switch (previewView) {
      case 0:
        return styles.desktopPreview;
      case 1:
        return styles.mobilePreview;
      case 2:
        return styles.invitationPreview;
      default:
        return styles.desktopPreview;
    }
  };

  const getPreviewImageUrl = () => {
    return previewImageUrls[previewView] || previewImageUrls[0];
  };

  return (
    <div className={styles.SelectedTemplatePreview}>
      <div style={{ display: 'flex' }}>
        <button
          onClick={() => setPreviewView(0)}
          className={'btnRounded ' + (previewView === 0 ? styles.Selected : '')}
          style={typeOfScreen === ScreenType.laptop ? { display: 'none' } : {}}
        >
          <img src="/assets/icons/icon-desktop.svg" alt="i" width={16} />
        </button>
        <button
          onClick={() => setPreviewView(1)}
          className={'btnRounded ' + (previewView === 1 ? styles.Selected : '')}
        >
          <img src="/assets/icons/icon-smartphone.svg" alt="i" width={16} />
        </button>
        <button
          onClick={() => setPreviewView(2)}
          className={'btnRounded ' + (previewView === 2 ? styles.Selected : '')}
          style={typeOfScreen === ScreenType.laptop ? { display: 'none' } : {}}
        >
          <img src="/assets/icons/icon-envelope.svg" alt="i" width={16} />
        </button>
      </div>
      <p>Scroll to view full website</p>
      <div className={getClassNameByPreviewView()}>
        <div>
          <div style={{ height: '20px', overflow: 'hidden' }}>
            <svg width="18" height="4" viewBox="0 0 18 4" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="2" cy="2" r="2" fill="#C7ADC1" />
              <circle cx="9" cy="2" r="2" fill="#C7ADC1" />
              <circle cx="16" cy="2" r="2" fill="#C7ADC1" />
            </svg>
          </div>
          <div
            id={`SelectedTemplatePreview`}
            style={{
              border: '1px solid #FDF6EB',
              height: '100%',
            }}
          >
            <img src={getPreviewImageUrl()} alt={'Selected Template Preview'} style={{ width: '100%' }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectedTemplatePreview;
